<template>
  <div class="monitor-report">
    <div style="height: 90px"></div>
    <div class="danTitle">监控报告</div>
    <section class="search-option">
      <!-- 国家 -->
      <el-select
        v-model="country"
        placeholder="国家/地区"
        class="cover-select el-select-long-officialt"
        filterable
        @change="selectCountry"
      >
        <el-option
          v-for="item in globalRunCountryList"
          :key="item.country"
          :label="item.countryZhName"
          :value="item.country"
        />
      </el-select>

      <!-- 公告期 -->
      <el-select
        v-model="announcement"
        filterable clearable
        placeholder="公告期"
        class="el-select-long-officialt"
      >
        <el-option
          v-for="(item,index) in announcementList"
          :key="index"
          :label="item"
          :value="item"
        />
      </el-select>

      <!-- 公告类别 -->
      <el-select
        v-model="intcls"
        filterable
        multiple
        collapse-tags
        clearable
        placeholder="公告类别"
        class="el-select-long-officialt"
        @change="selectIntcls"
      >
        <el-option
          v-for="(item,index) in intclsList"
          :key="index"
          :label="item.label"
          :value="item.label"
        />
      </el-select>

      <!-- 公告商标申请人 -->
      <el-input placeholder="公告商标申请人" v-model="applicantName" class="_one"/>

      <!-- 异议绝限 -->
      <el-date-picker class="el-date-editor-long-officialt one" v-model="validStartDate" type="date" placeholder="异议期限"/>
      <span class="zhi" style="font-size:14px;margin-right: 5px">至</span>
      <el-date-picker class="el-date-editor-long-officialt one" v-model="validEndDate" type="date" placeholder="异议期限"/>

      <el-button round class="el-long-defalut-button" style="margin-left: 20px" @click="getList">查询</el-button>

      <el-button round class="el-long-defalut-button" @click="resetCondtion">重置</el-button>

      <el-button round class="el-long-defalut-button" @click="importFn">导出</el-button>

      <el-button round class="el-long-defalut-button" @click="goback">返回</el-button>
    </section>

    <div class="monitoring_table">
      <vxe-grid
        header-row-class-name="vxe_grid_header"
        border="inner"
        height="720px"
        :highlight-current-row="true"
        :highlight-hover-row="true"
        :highlight-current-column="true"
        align="center"
        header-align="center"
        ref='xGrid'
        :header-cell-class-name="headerClassName"
        :cell-class-name="cellClassName"
        v-bind="gridOptions"
      >
        <!-- @click="goDetail(row)图片-->
        <template #imageUrl="{ row }">
            <img style="height: 100%;max-width: 100%" :src="row.trademarkImage" v-if="row.trademarkImage" alt=""/>
            <span style="display: flex;align-items: center" v-else>{{row.trademarkName}}</span>
        </template>

        <!--公告类别announcementTrademark-->
        <template #announcementTrademark="{ row }">
          <img style="height: 100%;max-width: 100%"  :src="returnUrl(row.imageUrl)" v-if="row.imageUrl" alt=""/>
          <span style="display: flex;align-items: center" v-else>{{row.sbmc}}</span>
        </template>

        <!--monitorType-->
        <template #monitorType="{ row }">
          <div>{{row.monitorType == 1 ? '近似商标监控' : '竞争对手监控'}}</div>
        </template>

        <!--operation操作-->
        <template #announcement="{ row }">
          <div>
            {{row.tmapplicant||row.tmapplicantEn}}
          </div>
        </template>

        <!--分页-->
        <template #pager>
          <vxe-pager
            border
            background
            :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
            :current-page.sync="current"
            :page-size.sync="size"
            :total="total"
            @page-change="handleCurrentChange">
          </vxe-pager>
        </template>

        <!--自定义空数据模板-->
        <template #empty>
          <div>
            {{$t('personal_lang1.noDate')}}
          </div>
        </template>
      </vxe-grid>
    </div>

  </div>
</template>

<script>
  export default {
    name: "monitor-report",
    data() {
      return {
        gridOptions: {
          border: true,
          resizable: true,
          showHeaderOverflow: true,
          showOverflow: true,
          highlightHoverRow: true,
          keepSource: true,
          id: 'full_edit_1',
          height: 600,
          rowId: 'id',
          sortConfig: {
            trigger: 'cell',
            remote: true
          },
          columnKey: true,
          scrollX: {
            enabled: false
          },
          data: [],
          Props: {
            height: '720px',
            stripe: true,
            cellStyle: this.cellStyle
          },
          columns: [
            {
              field: 'countryNames',
              title: '所属国家/地区'
            },
            {
              field: 'imageUrl', title: '监控商标', slots: {
                // 使用插槽模板渲染
                default: 'imageUrl',
              }
            },
            {
              field: 'isImage',
              title: '监控类型'
            },
            {
              field: 'niceCodes',
              title: '监控类别'
            },
            {
              title: '公告商标',
              slots: {
                default: 'announcementTrademark'
              }
            },
            {
              field: 'countryName',
              title: '所属国家/地区'
            },
            {
              field: 'tmapplicant',
              title: '公告商标申请人',
              slots: {
                default: 'announcement'
              }
            },
            {
              field: 'appno',
              title: '申请号'
            },
            {
              field: 'intcls',
              title: '类别'
            },
            {
              field: 'trialnum',
              title: '公告期'
            },
            {
              field: 'validdate',
              title: '异议绝限'
            },
            {
              field: 'monitorType',
              title: '监控类型',
              slots: {
                default: 'monitorType'
              }
            }
          ],
          importConfig: {
            remote: true,
            importMethod: this.importMethod,
            types: ['xlsx'],
            modes: ['insert']
          },
          exportConfig: {
            remote: true,
            exportMethod: this.exportMethod
          },
          checkboxConfig: {
            reserve: true,
            highlight: true,
            range: true
          },
          editConfig: {
            trigger: 'click',
            mode: 'row',
            showStatus: true
          }
        },
        getReport:[],
        country: "",
        announcement: "",
        intcls: [],
        applicantName: "",
        validStartDate : "",
        validEndDate: "",
        //后台分页
        current: 1,
        size: 50,
        total: 10,
        extensionCondtion: {
          size: 10,
          current: 1,
          total: 0,
        },
        //  提异议弹框
        dialogVisible:false,
        personal: '',
        email: '',
        phone: '',
        jktextarea: '',
        validate2: '',
        imgUrl: '',
        ids:'',
        token:'',
        isShow:false,
        currentdate:'',
        globalRunCountryList: [],
        announcementList: [],
        intclsList: [],
        isChooseAllIntcls: false,
      }
    },
    created() {
      this.getNowFormatDate()
      this.intclsList.push({
        value: "",
        label: "全部",
      });
      for(let i = 1; i < 46; i++) {
        let num = i > 9 ? "" + i : "0" + i;
        this.intclsList.push({
          value: i,
          label: num,
        })
      }
    },
    mounted() {
      this.getList();
      this.getCountryAndTrail();
    },
    methods: {
      //表头的设计
      headerClassName({ $rowIndex, column, columnIndex, $columnIndex }){
        if ($columnIndex == 0 || $columnIndex == 1 || $columnIndex == 2 || $columnIndex == 3) { return 'header_class_one' }
        else { return 'header_class_two' }
      },
      cellClassName({row, column, $columnIndex}) {
        if ($columnIndex == 1 || $columnIndex == 4) { return 'col-orange' }
      },
      //给图片添加地址
      returnUrl(url){ return url },
      goback(){ window.history.go(-1) },

    //查询返回数据需要加上此url
      queryDataUrl(data) {
        if (data && data.includes('data:image')) return data
        else if (data && !data.includes('http://')) return rootImgUrl_qiufeng + data
        else return ''
      },

      selectIntcls(v) {
        if(v.includes('全部')) { this.intcls = this.intclsList.filter(i => i.label != "全部").map(i => i.label) }
        else { this.intcls = v }
      },

      selectCountry(v) { this.announcementList = this.globalRunCountryList.filter(i => i.country == v)[0].trialNumList.sort((a, b) => (b - a)) },

      getCountryAndTrail() {
        this.$axios.get("/trademark/monitoring/getAllTrialnums").then(({data}) => {
          if(data.code != 0 ) { this.$message(data.msg) }
          else if(data.code == 0) {
            this.globalRunCountryList = data.data
            this.country = 'CN'
            this.announcementList = data.data.filter(item => item.country == 'CN')[0].trialNumList.sort((a, b) => (b - a))
          }
        })
      },

      importFn(){
        const myDate = new Date()
        const Y = myDate.getFullYear()
        const M = (myDate.getMonth()<9) ? "0"+(myDate.getMonth()+1) : myDate.getMonth()+1
        const D = (myDate.getDate()<10)? "0"+myDate.getDate() : myDate.getDate()
        let date  = Y + M + D

        let reportParam = {
          'country': this.country,
          'trialnum': this.announcement, //公告期号
          'intcls': this.intcls.join(';'), //商标分类，多个使用分号（;）分割
          'applicantName': this.applicantName, //申请人名称
          'validStartDate': this.validStartDate, //异议期限开始日期
          'validEndDate': this.validEndDate, //异议期限结束日期
          'current': this.current,
          'size': this.size,
        }
        this.$axios.get("/trademark/monitoring/userMonitoringExport",{
          params:reportParam,
          responseType: "arraybuffer"
        })
          .then((res) => { // 处理返回的文件流
            const content = res.data
            const blob = new Blob([content])
            const fileName = '商标监控报告-' + date + '.xls'
            if ('download' in document.createElement('a')) { // 非IE下载
              const elink = document.createElement('a')
              elink.download = fileName
              elink.style.display = 'none'
              elink.href = URL.createObjectURL(blob)
              document.body.appendChild(elink)
              elink.click()
              URL.revokeObjectURL(elink.href) // 释放URL 对象
              document.body.removeChild(elink)
            }
            else { // IE10+下载
              navigator.msSaveBlob(blob, fileName)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      resetCondtion() {
        this.country = ''
        this.announcement = ''
        this.intcls = []
        this.applicantName = ''
        this.validStartDate = ''
        this.validEndDate = ''
        this.getList()
      },
      getList(){
        let reportParam = {
          'country': this.country,
          'trialnum': this.announcement, //公告期号
          'intcls': this.intcls.join(';'), //商标分类，多个使用分号（;）分割
          'applicantName': this.applicantName, //申请人名称
          'validStartDate': this.validStartDate, //异议期限开始日期
          'validEndDate': this.validEndDate, //异议期限结束日期
          'current': this.current,
          'size': this.size,
        }
        this.$axios.get("/trademark/monitoring/getUserMonitoringReport",{params:reportParam})
          .then(({data}) => {
            if(data.code == 0){
              if(data.data.records.length){
                this.getReport = data.data.records
                this.gridOptions.data = data.data.records
                this.total = data.data.total //一共多少条数据
                this.size = data.data.size //一共多少条数据
              }
              else{
                this.getReport = [];
                this.gridOptions.data = []
              }
            }
          })
          .catch((error) => {
            console.log(error);
          })
      },
      //  分页
      handleSizeChange(val) {
        this.size = val
        this.getList()
      },
      handleCurrentChange({currentPage, pageSize}) {
        this.current = currentPage
        this.getList()
      },
      //  获取当前日期
      getNowFormatDate(){
        var date = new Date()
        var seperator1 = "-"
        var year = date.getFullYear()
        var month = date.getMonth() + 1
        var strDate = date.getDate()

        if (month >= 1 && month <= 9) { month = "0" + month }

        if (strDate >= 0 && strDate <= 9) { strDate = "0" + strDate }

        this.currentdate = year + seperator1 + month + seperator1 + strDate
      },
      objection(id) {
        this.isCanOpposition({tmIds: id, lang: 'ch'})
          .then((data) => {
            if (data.code == 200) {
              var obj = {'ids': id}
              localStorage.setItem('test', JSON.stringify(obj))
              this.topBottom = 'bottom'
              this.$router.push({
                path: "/opposition/extension/perfectInformation",
                query: {step: 2},
              })
            }
            else {
              this.$message(data.message)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      },
      submit(){
        if(this.personal == ""){ this.$message("联系人不能为空") }
        else if(this.phone == ""){ this.$message("联系电话不能为空") }
        else if(this.email == ""){ this.$message("联系邮箱不能为空") }
        else if(this.validate2 == ''){ this.$message("验证码不能为空") }
        else{
          this.createLeaveMessage({
            monitoringRecordId:this.ids,
            contact:this.personal,
            phone:this.phone,
            email:this.email,
            message:this.jktextarea,
            postType:1,
            captcha:this.validate2,
            token:this.token,
          })
            .then((data) => {
              if(data.code == 200){
                this.dialogVisible = false
                this.$message("提交成功")
                this.getList()
                this.personal=""
                this.phone=""
                this.email=""
                this.jktextarea=""
              }
              if(data.code == 400){
                this.$message(data.message)
              }
            })
            .catch((error) => {
              console.log(error);
            })
        }
      },
      cancel(){
        this.dialogVisible = false
        this.personal = ""
        this.email = ""
        this.phone = ""
        this.jktextarea = ""
        this.validate2 = ""
      },
    },
  }
</script>
<style lang="less">
  .monitor-report{
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .vxe-table--header {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }


    .vxe_grid_header {
      border-radius: 15px 15px 0px 0px!important;
      overflow: hidden!important;
      background: #e2efff!important;
    }

    .header_class_one{
      background: #4877e7!important;
      color: #ffffff;
    }

    .header_class_two{
      background: #e2efff!important;
    }

    .row--current, .row--hover {
      background: #ffffff !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }
    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }
  }

</style>

<style lang="less">
  .trademarkMonitoring{
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar {
      width: 10px; // 横向滚动条
      height: 10px; // 纵向滚动条 必写
    }
    // 滚动条的滑块
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-thumb {
      background: hsla(0, 0%, 53%, .1);
      border-radius: 10px
    }
    /deep/.vxe-table--body-wrapper::-webkit-scrollbar-track {
      background: hsla(0, 0%, 53%, .1)
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
    .sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
      background-color: #dfecfb;
    }

    .sortable-column-demo .vxe-header--row .vxe-header--column.col--fixed {
      cursor: no-drop;
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }

    .vxe-table--header {
      border-radius: 15px 15px 0px 0px;
      overflow: hidden;
    }

    .row--current, .row--hover {
      background: #ffffff !important;
      -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
      -webkit-transform: translate3d(0, 0px, -2px);
      transform: translate3d(0, 1px, -2px);
    }

    .vxe-body--row {
      transition: all 0.2s ease-in;
    }
    .col-orange div {
      width: 118px !important;
      display: flex !important;
      height: 48px !important;
      padding: 2px 0 !important;
      justify-content: center !important;
    }



  }

</style>
<style scoped lang="less">
  .monitoring_table {
    /*width: 98%;*/
    padding: 10px;
    background: white;
    border: 1px solid #ddddde;
  }

  .monitor-report {
    .danTitle{
      height: 50px;
      line-height: 50px;
      text-align: center;
      font-size: 20px;
      color: #333;
      font-weight: 600;
    }
    background: #f3f3f3;
    padding: 0 22px 18px 15px;
    section.search-option{
      min-height: 100px;
      background-color: #ffffff;
      border-radius: 3px;
      border: solid 1px #e4e4e4;
      margin-bottom: 20px;
      padding-top: 30px;
      padding-left: 32px;
      .el-select-long-officialt {
        width: 180px;
        height: 34px;
        border-radius: 3px;
        margin-bottom: 15px;
        background: #ffffff;
        .el-input {
          input.el-input__inner {
            border: 1px solid #cccccc;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .one, .el-input {
        width: 180px;
        input {
          height: 34px !important;
          border-color: #ccc;
          color: inherit;
          padding: 0 4px;
        }
      }
      ._one /deep/.el-input__inner {
        height: 34px;
      }
      .el-date-editor-long-officialt {
        width: 180px;
        height: 34px;
        border-radius: 3px;
        background: #ffffff;
        line-height: 32px;
        input.el-input__inner {
          border: 1px solid #cccccc;
          text-indent: 42px;
        }
      }
    }
    .monitor-report_table {
      /*width: 98%;*/
      padding: 10px;
      background: white;
      border: 1px solid #ddddde;
    }
    section.result{
      margin: 10px 0 0;
      position: relative;
      width:100%;
      background:#fff;
      .fyBlock {
        /*margin-left: 40%;*/
        margin-top: 30px;
        padding-bottom:1%;

      }
      .tanchu {
        margin-bottom: 15px;
        span {
          display: inline-block;
          margin-right: 35px;
        }
        .el-input {
          width: 380px;
          .el-input__inner {
            height: 36px;
          }
        }
        .code {
          width: 97px;
          height: 40px;
          margin-left: 14px;
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }
</style>
